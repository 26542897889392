import "../css/common.css"
import "../css/contact.css"
import React, {useState} from 'react';
import closeButtonIcon from '../assets/icons/x-square.svg'



function TermsAndConditions(props)
{
    function closeForm()
    {
        props.onClose();
    }

    let Title = "Termini e Condizioni";
    let paragraphs = [ 
        [ 
            "Cancellazione in caso di eventi meteorologici avversi",
            "Il tour operator monitora quotidianamente le condizioni meteo, qualora il tour non possa essere " +
            "svolto provvederemo a effettuare il rimborso o a cambiare la data.<br/><br/>" +
            "Nel caso in cui il tour abbia luogo e il cliente non vi prenda parte la quota non sarà rimborsata." +
            "<br/><br/>"
        ],
        [
            "Reclami e rimborsi",
            "Se durante lo svolgimento del tour il cliente ha un qualsiasi tipo di problema è pregato di " +
            "informare immediatamente la guida. Cercheremo di risolvere il problema al più presto.<br/><br/>" +
            "In caso di reclamo ci metteremo subito in contatto con il nostro partner locale. I reclami" +
            "vengono sempre presi in seria considerazione, ai fini di migliorare il servizio nostro e del " +
            "partner.<br/><br/>"+
            "In caso di reclami dovuti alle avverse condizioni meteo o altri fattori non è possibile richiedere " +
            "il rimborso. Il tour operator locale non prevede il rimborso per I tour in corso o conclusi." +
            "<br/><br/>" 
        ],
        [
            "Restrizioni inerenti le barche veloci e i catamarani",
            "Qualora sussita uno dei seguenti casi è necessario contattarci prima di aquistare il tour: <br/>" +
            "Gravidanza, bambini sotto ai 2 anni di età, persone di 70 anni o più, persone con condizioni " +
            "mediche in atto, persone con malattie alle ossa, persone in fase di recupero dovuto a intervento " +
            "chirurgico, persone con supporti motori o su sedia a rotelle.<br/><br/> " +
            "Se il tour viene acquistato prima di contattarci e sussiste una delle condizioni espresse sopra " +
            "non potremo procedere con il rimborso."  +
            "<br/><br/>"
        ],
        [
            "Responsabilità",
            "Il nostro partner, fornitore locale dei tour, è responsabile per I suoi prodotti e servizi, in accordo " +
            "con la legge applicabile.<br/><br/>" +
            "ILMIOVIAGGIOABANGKOK, in qualità di agenzia che offre I servizi del partner locale, non è in " +
            "alcun modo responsabile degli incidenti causati dai servizi e prodotti offerti dal partner.<br/>" +
            "I fornitori dei servizi e dei prodotti che si trovano su questo sito sono fornitori indipendenti, e non " +
            "agenti o dipendenti della nostra compagnia. <br/><br/>" +
            "ILMIOVIAGGIOABANGKOK non è responsabile per gli errori, omissioni, garanzie, infrazioni, atti " +
            "di negligenza o miscondotta perpetrati dai nostri fornitori locali o dalla guida presente durante il " +
            "tour.<br/> ILMIOVIAGGIOABANGKOK non è responsabile per ferite personali, morte, danni a cose o " +
            "altri danni e/o spese risultanti dalla prenotazione di un prodotto o servizio sul nostro portale.<br/><br/> " +
            "Non siamo altresì responsabili e non provvederemo al rimborso in caso di ritardi, cancellazioni, " +
            "overbooking, scioperi, cause di forza maggiore o altre cause che vanno oltre al nostro diretto " +
            "controllo, così come in caso di costi aggiuntivi, o cambiamenti ausati da atti governativi " +
            "provenienti dalle autorità thailandesi." +
            "<br/><br/>"
        ]
    ]

    let paragraphRender = "";

    try{
        
        paragraphRender = paragraphs.map((paragraph) =>
            <div class="row transparent contact-row">
                <div class="col-sm-4 transparent"><p class="privacy-paragraph-title"><b>{paragraph[0]}</b></p></div>
                <div class="col-sm-8 transparent"><p class="privacy-paragraph-content" dangerouslySetInnerHTML={{ __html: paragraph[1]}}></p></div>  
            </div>
        )
    }
    catch{
        
    }

    return (
        <div class="background-unavailable" id="privacy" style={{"overflow" : "auto", zIndex : '101'}}>
            <div class="background-contact-box">
                <div class="contact-top-bar transparent">
                    <button class="close-button button-cst" onClick={closeForm}><i class="bi bi-x-square"></i></button>
                </div>
                <div class="contact-form-border transparent">
                    <div class="row transparent contact-row">
                        <h1>{Title}</h1>
                    </div>
                    {paragraphRender}
                </div>
            </div>
        </div>
    )
}
export default TermsAndConditions;