import "../css/common.css"
import "../css/mainpage.css"
import SplitText from "../Common";
import ArticlePreview from "../components/ArticlePreview"
import Article from "../components/Article"
import config from "../Config.json";


import { Route, useParams} from 'react-router-dom';
import { useState } from "react"


export function ServiceRoute()
{

    let servicesPageRender = "";
    var [contentLoaded, setContentLoaded] = useState(false);
    const [servicesList, setServicesList] = useState({});

    if(!contentLoaded)
    {
        fetch(config.backendUrl + "/content/services", {method: "GET"})
        .then(response => {
            response.json().then((body)=> 
            {
              setServicesList(body);
              setContentLoaded(true);
            });
        }); 
    }
    try{
        if(contentLoaded)
        {
            servicesPageRender = servicesList.map((service) =>
            <Route path={service.index} component={<Article/>}></Route>
            )
        }
    }
    catch{

    }

    return (
        {servicesPageRender}
    )
}

export function Services()
{
    var [contentLoaded, setContentLoaded] = useState(false);
    const [servicesList, setServicesList] = useState({});
    window.scrollTo(0, 0)

    if(!contentLoaded)
    {
        fetch(config.backendUrl + "/content/services", {method: "GET"})
        .then(response => {
            response.json().then((body)=> 
            {
              setServicesList(body);
              setContentLoaded(true);
            });
        }); 
    }

    let titleRender = "";
    let servicePreviewTitleRender = "";
    let servicesPreviewRender = "";
    let serviceRender = "";
    let horizontalLineRender = "";

    let params = useParams();

    if (params.serviceID)
    {
        if(contentLoaded)
        {
            serviceRender = (
                <Article
                    ID={"S" + params.serviceID}
                    isService={servicesList[params.serviceID - 1].service}
                    title={servicesList[params.serviceID - 1].title}
                    subtitle={servicesList[params.serviceID - 1].subtitle}
                    keyPoints={servicesList[params.serviceID - 1].keyPoints}
                    meetingPoints={[]}
                    availableTimes={[]}
                    bookingEntities={[]}
                    minimumBookingQuantity={0}
                    bookingAdditionalMessages={[]}
                    price={servicesList[params.serviceID - 1].price}
                    image={servicesList[params.serviceID - 1].imageUrl}
                    content={servicesList[params.serviceID - 1].description}
                    duration={servicesList[params.serviceID - 1].duration}
                    showBookingDetails={false}
                    disabledDates={[]}
                    disabledDays={[]}
                    lastAvailableBooking={""}
                    cancellationPolicy={""}
                ></Article>
            )
            servicePreviewTitleRender = (
                <h2 className="article-paragraph-title text-align-left">Altri servizi:</h2>
            )
            horizontalLineRender = <hr style={{'margin-top' : '5rem'}}/>;
        }
    }
    else{
        titleRender = (
            <h1 class="title-page"><SplitText copy="Consulenze" role="heading" /></h1>
        )
    }

    try{
        if(contentLoaded)
        {
            servicesPreviewRender = servicesList.map((service) =>
            <ArticlePreview
                containerCss="paragraph-preview-container"
                imageCss="paragraph-preview-image"
                baseUrl='services'
                index={service.index}
                isService={service.service}
                title={service.title}
                subtitle={service.subtitle}
                keyPoints={service.keyPoints}
                price={service.price}
                image={service.imageUrl}
                showImageOnPreview={true}
                duration={service.duration}
                delay={0}> 
            </ArticlePreview>
            )
        }
        else
            servicesPreviewRender = <p>Caricando i contenuti...</p>

    }
    catch{
        servicesPreviewRender = <p>Errore durante il caricamento</p>
    }

    return (
        <div class="page-background">
            {titleRender}
            {serviceRender}
            {horizontalLineRender}
            {servicePreviewTitleRender}
            <div className='main-grid'>
                {servicesPreviewRender}
            </div>
            
        </div>
    );
}
