import '../css/checkout.css'
import '../css/common.css'
import { Context,GetCart, EmptyCart, CheckoutComplete, VerifyLastCheckout, ClearLastCheckout, AddCheckoutDetails} from '../components/ShoppingCart';
import React, {useState, useContext} from 'react';
import PaymentForm from '../components/PaymentForm';
import TermsAndConditions from "../Views/TermsAndConditions";
import Privacy from "../Views/Privacy";
import { isValidEmail } from '../components/Utils';
import config from "../Config.json";
import CardLogo from "../assets/images/CardLogo.png"; 
import VisaLogo from "../assets/images/VisaLogo.png"; 
import MastercardLogo from "../assets/images/MastercardLogo.png"; 
import PaypalLogo from "../assets/images/PaypalLogo.png"; 

function Checkout()
{
    const [cartIsEmpty, setCartIsEmpty] = useState(true);
    const [lastCheckout, setLastCheckout] = useState(false);
    const [inputName, setInputName] = useState('');
    const [inputSurname, setInputSurname] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputEmailConfirm, setInputEmailConfirm] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const [showPaymentResult, setShowPaymentResult] = useState('false');
    const {items, setItems} = useContext(Context) || {};
    const [termsPage, setTermsPage] = useState ('');
    const [privacyPage, setPrivacyPage] = useState ('');
    const [lastOrderNumber, setLastOrderNumber] = useState('');
    const [downloadInProgress, setDownloadInProgress] = useState(false);

    function openTermsPage()
    {
        setTermsPage(<TermsAndConditions onClose={closeTermsPage}></TermsAndConditions>);
    }

    function closeTermsPage()
    {
        setTermsPage('');
    }

    function openPrivacyPage()
    {
        setPrivacyPage(<Privacy onClose={closePrivacyPage}></Privacy>);
    }

    function closePrivacyPage()
    {
        setPrivacyPage('');
    }

    const handleChange = event =>  {
        switch(event.target.id){
            case "inputNameCheckout":
                setInputName(event.target.value);
                break;
            case "inputSurnameCheckout":
                setInputSurname(event.target.value);
                break;
            case "inputEmailCheckout":
                setInputEmail(event.target.value);
                break;
            case "inputEmailConfirmCheckout":
                setInputEmailConfirm(event.target.value);
                    break;
            case "inputPhoneCheckout":
                setInputPhone(event.target.value);
                break;
            case "inputMessageCheckout":
                setInputMessage(event.target.value);
                break;
        }
      };

    function validateData()
    {
        let alertBox = document.getElementById('alertBoxCheckout');
        var name = document.getElementById('inputNameCheckout').value;
        var surname = document.getElementById('inputSurnameCheckout').value;
        var email = document.getElementById('inputEmailCheckout').value;
        var emailConfirm = document.getElementById('inputEmailConfirmCheckout').value;
        var phone = document.getElementById('inputPhoneCheckout').value;
        var message = document.getElementById('inputMessageCheckout').value;
        var privacyAccepted = document.getElementById('acceptPrivacyCheckout');

        if(name == '' || name == null)
        {
            alertBox.innerText = "Il campo Nome e' obbligatorio";
            alertBox.classList.add("alert-box-error");
            return false;
        }

        
        if(surname == '' || surname == null)
        {
            alertBox.innerText = "Il campo Cognome e' obbligatorio";
            alertBox.classList.add("alert-box-error");
            return false;
        }

        if(!isValidEmail.test(email))
        {
            alertBox.innerText = "Inserisci un indirizzo e-mail valido";
            alertBox.classList.add("alert-box-error");
            return false;
        }

        if(email != emailConfirm)
        {
            alertBox.innerText = "Le due e-mail inserite non corrispondono";
            alertBox.classList.add("alert-box-error");
            return false;
        }

        if(!privacyAccepted.checked)
        {
            alertBox.innerText = "E' necessario fornire il consenso in merito all'informativa per la privacy";
            alertBox.classList.add("alert-box-error");
            return false;
        }    

        alertBox.innerText = "";
        alertBox.classList.remove("alert-box-error");

        AddCheckoutDetails({
            email : email,
            phone : phone,
            name : name,
            surname : surname,
            message : message
        });
        return true;
    }
    
    function clearInputData()
    {
        setInputName('');
        setInputSurname('');
        setInputEmail('');
        setInputEmailConfirm('');
        setInputPhone('');
        setInputMessage('');
        var privacyAccepted = document.getElementById('acceptPrivacy');
        privacyAccepted.checked = false;
    }

    try{
        if(VerifyLastCheckout() && !lastCheckout)
            setLastCheckout(true);
    }
    catch
    {}

    let currentCart = GetCart();
    let currentCartRender;
    try{
        currentCartRender = currentCart.items.map((item) =>
        item.isService ? 
        <tr className='table-row table-small'>
            <td className='table-desc'>
                {item.name}
            </td>
            <td className='table-price'>
                {item.quantity}
            </td>
            <td className='table-price'>
                {item.price * item.quantity} &euro;
            </td>
        </tr>
        :
        <tr className='table-row table-small'>
            <td className='table-desc'>
                {item.name}&nbsp;|&nbsp;{item.date}&nbsp;|&nbsp;{item.time}&nbsp;|&nbsp;{item.meetingPoint}
            </td>
            <td className='table-price'>
               {item.quantityAdult} Adulti | {item.quantityChildren} Bambini | {item.quantityInfant} Infanti
            </td>
            <td className='table-price'>
                {item.priceAdult * item.quantityAdult + item.priceChildren * item.quantityChildren} &euro;
            </td>
        </tr>
    )
    currentCartRender = (
        <table>
            <tr className='table-row'>
                <th className='table-desc'>
                    Descrizione
                </th>
                <th>
                    
                </th>
                <th className='table-price'>
                    Prezzo
                </th>
            </tr>
            {currentCartRender}
            <tr className='table-row'>
                <td colSpan={2} className='table-desc'>
                    <b>Totale</b>
                </td>
                <td className='table-price'>
                    {currentCart.totalPrice} &euro;
                </td>
            </tr>
        </table>
    )
    if(cartIsEmpty)
        setCartIsEmpty(false);
    }
    catch{
        currentCartRender = '';
    }

    
    

    let downloadRender = !downloadInProgress ? '' :
    (
        <div style={
            {
                position : 'absolute',
                width : '100vw',
                height : '100vh',
                backgroundColor : 'rgba(50,50,50,0.5)',
                left : 0,
                top : 0,
                zIndex : 1000,
                fontSize : '1.5rem',
                paddingTop : '30vh'
            }}>
            <p style={{
                marginLeft: '10vw',
                marginRight : '10vw',
                padding: '0.5rem',
                backgroundColor : 'white'
            }}>
                Stiamo processando la tua richiesta, per favore attendi...
            </p> 
        </div>
    )

    function orderCompleted(status, id)
    {
        CheckoutComplete(status);
        setLastOrderNumber(id);
        setLastCheckout(true);
    }

    function downloadInvoice(id, email)
    {
        setDownloadInProgress(true);
        fetch(config.backendUrl + '/orders/print', {
            method: "POST",
             headers: {
              "Content-Type": "application/json",
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
                id : lastOrderNumber,
                email : inputEmail
            })
          }).then(res => res.blob())
          .then(data => {
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = "Ricevuta.pdf";
            a.click();
            setDownloadInProgress(false);
          });
    }

    if(lastCheckout && VerifyLastCheckout() == 'COMPLETED')
        return(
            <>            
            {downloadRender}
                <div className='page-background'>
                    <h3>Ordine completato</h3>
                    
                    Grazie mille per il tuo acquisto.<br/>
                    Il tuo ordine e' andato a buon fine. Riceverai a breve una e-mail con i dettagli della tua prenotazione.<br/><br/>
                    <div>
                        Fai riferimento al seguente numero d'ordine qualora avessi bisogno di contattarci all'indirizzo e-mail <strong>info@ilmioviaggioabangkok.net</strong>
                    </div>
                    <br/><br/>
                    <div>
                        <h4>Riferimento ordine: {lastOrderNumber} </h4>
                    </div>
                    <div>
                        <h4><a href='#' onClick={() => downloadInvoice(lastOrderNumber, inputEmail)}>Scarica ricevuta</a></h4>
                    </div>
                    
                    <div className='contact-row' style={{width : '100%'}}>
                        <button class="btn btn-light button-cst" onClick={() => {setItems(0); ClearLastCheckout(); EmptyCart(); setCartIsEmpty(true);setLastCheckout(false)}}>Ok</button>
                    </div>
                </div>
            </>

        )

    if(lastCheckout && VerifyLastCheckout() != 'COMPLETED')
        return(
            <div className='page-background alert-box-error'>
                Siamo spiacenti, ma qualcosa e' andato storto durante il pagamento<br/>
                Verifica i dati inseriti e riprova piu' tardi.<br/>
                Non esitare a contattarci per qualsiasi dubbio.
                <div className='contact-row' style={{width : '100%'}}>
                    <button class="btn btn-light button-cst" onClick={() => {ClearLastCheckout();setLastCheckout(false)}}>Torna al checkout</button>
                </div>
            </div>
        )

    if(cartIsEmpty)
        return(
            <div className='page-background'>
                Il tuo carrello e' vuoto!
            </div>
        )

    return (
        <>
        {termsPage}
        {privacyPage}
            <div className='page-background'>
                <h3>Checkout</h3>
                {currentCartRender}
                <div className='contact-row' style={{width : '100%'}}>
                    <button class="btn btn-light button-cst controls-right" onClick={() => {EmptyCart(); setItems(0); setCartIsEmpty(true)}}>Svuota carello</button>
                </div>
                <div class='confirm-order-box'>
                    <div class="confirm-order-info-box">
                        <div class="row transparent contact-row ">
                            <div class="col-sm-12 transparent title-section">
                                Inserisci i tuoi dati per la prenotazione!
                            </div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Nome:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputNameCheckout"
                                    onChange={handleChange}
                                    value={inputName}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Cognome:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputSurnameCheckout"
                                    onChange={handleChange}
                                    value={inputSurname}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                E-mail:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputEmailCheckout"
                                    name="message"
                                    onChange={handleChange}
                                    value={inputEmail}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Conferma e-mail:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputEmailConfirmCheckout"
                                    name="message"
                                    onChange={handleChange}
                                    value={inputEmailConfirm}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Telefono:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputPhoneCheckout"
                                    onChange={handleChange}
                                    value={inputPhone}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Richieste aggiuntive:
                            </div>
                            <div class="col-sm-7 transparent">
                                <textarea id="inputMessageCheckout"
                                    onChange={handleChange}
                                    value={inputMessage}
                                    style={{"width" : "90%" , "resize" : "none"}}
                                    rows="7"></textarea>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-2 transparent"></div>
                            <div class="col-sm-1 transparent">
                                <input type="checkbox" id="acceptPrivacyCheckout" class="checkbox-cst"></input>
                            </div>
                            <div class="col-sm-9 transparent">
                                <label for="acceptPrivacyCheckout">Ho preso visione dell'informativa sulla <b onClick={openPrivacyPage}>privacy</b></label>
                            </div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-2 transparent"></div>
                            <div class="col-sm-1 transparent">
                                <input type="checkbox" id="acceptTermsCheckout" class="checkbox-cst"></input>
                            </div>
                            <div class="col-sm-9 transparent">
                                <label for="acceptTermsCheckout">Accetto <b onClick={openTermsPage}>termini e condidizioni</b>  del servizio</label>
                            </div>
                        </div>
                        <div class="alert-box" id="alertBoxCheckout"></div>
                    </div>
                    <div className='confirm-order-price-box'>
                        <div className='row transparent contact-row'>
                            <div className='col-sm-12 title-section'>
                                Conferma e paga!
                            </div>
                        </div>
                        <div className='row transparent contact-row'>
                            <div className='col-sm-6'>
                            <b>Totale:</b>
                            </div>
                            <div className='col-sm-6'>
                                {currentCart.totalPrice} &euro;
                            </div>
                        </div>
                        <PaymentForm 
                        sendResult={(status, id) =>  orderCompleted(status, id)}
                        validateData={validateData}
                        />
                        <div>
                            <img src={CardLogo} className='card-logo'></img>
                            <img src={VisaLogo} className='card-logo'></img>
                            <img src={MastercardLogo} className='card-logo'></img>
                            <img src={PaypalLogo} className='card-logo'></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout;