import React, { useState } from "react";
import ReactDOM from "react-dom"
import config from "../Config.json";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { GetCart, GetCheckoutDetails } from "./ShoppingCart";

function PaymentForm(props) {

  const [orderRefNumber,setOrderRefNumber] = useState("");

  function getOrder()
  {
    return {
      contactInfo:  GetCheckoutDetails(),
      cart : GetCart()}
      ;
  }

  const createOrder = (data) => {
    // Order is created on the server and the order id is returned
    if(!props.validateData())
      return null;
      
    return fetch(config.backendUrl + '/orders/create', {
      method: "POST",
       headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product skus and quantities
      body: JSON.stringify(getOrder()),
    })
    .then((response) => response.json())
    .then((order) => order.id);
  };
  const onApprove = (data) => {
     // Order is captured on the server and the response is returned to the browser
     return fetch(config.backendUrl + "/orders/capture", {
      method: "POST",
       headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderId: data.orderID
      })
    })
    .then(response => {
        response.json().then((body)=> props.sendResult(body.status, body.id));
    });
  };
  
  return (
      <PayPalScriptProvider options={{ "client-id": config.paypalId, "currency" : "EUR", "enable-funding": "card,credit"}}>
            <PayPalButtons style={{ layout: "horizontal"}}
              createOrder={(data) => createOrder(data)}
              onApprove={(data) => onApprove(data)}/>
        </PayPalScriptProvider>
  );
}

export default PaymentForm;