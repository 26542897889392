import "../css/common.css"
import "../css/mainpage.css"
import BangkokSmartImage from "../assets/images/skyline1.jpg"
import BangkokFullImage from "../assets/images/mercatoGalleggiante.jpg"
import TravelWithPetsImage from "../assets/images/travelWithPets.jpg"
import ParSection from "./ArticlePreview.js"
import SplitText from "../Common";
import ArticlePreview from "./ArticlePreview.js"
import config from "../Config.json";
import { useState } from "react"


function ServicesPreview(props)
{
    var [contentLoaded, setContentLoaded] = useState(false);
    const [servicesList, setServicesList] = useState({});
    const redirectPage = "/" + props.baseUrl;

    if(!contentLoaded)
    {
        fetch(config.backendUrl + "/content/" + props.baseUrl + "?top=true", {method: "GET"})
        .then(response => {
            response.json().then((body)=> 
            {
              setServicesList(body);
              setContentLoaded(true);
            });
        }); 
    }

    
    let servicesRender = "";

    try{
        if(contentLoaded)
        {
            servicesRender = servicesList.map((service) =>
            <ArticlePreview
                containerCss="paragraph-preview-container"
                imageCss="paragraph-preview-image"
                isService={service.service}
                baseUrl={props.baseUrl}
                index={service.index}
                title={service.title}
                subtitle={service.subtitle}
                duration={service.duration}
                keyPoints={service.keyPoints}
                price={service.bookingEntities? service.bookingEntities[0].price : service.price}
                image={service.imageUrl}
                showImageOnPreview={service.showImageOnPreview}
                delay={service.delay}> 
            </ArticlePreview>
        )
        }
        else
        {
            servicesRender = 'Caricando i contenuti...'
        }
    }
    catch{
        
    }

    return (
        <div>
            <div className='main-grid-preview'>
                {servicesRender}
            </div>
            <div style={{marginTop : '2rem'}}>
                <a className="a-cst" href={redirectPage}>Vedi tutti</a>
            </div>
        </div>
    );
}

export default ServicesPreview;