import "../css/common.css"
import "../css/mainpage.css"
import SplitText from "../Common";
import Preview from "../components/Preview";
import AboutUs from "../components/AboutUs";
import toursImage from '../assets/images/mercatoGalleggiante.jpg';
import servicesImage from '../assets/images/skyline1.jpg';
import ServicesPreview from '../components/ServicesPreview';

function Home()
{
    return (
        <>
            <div className="page-background">
            <div class="background-style">
                <h3 style={{'visibility' : 'hidden', 'position' : 'absolute', 'height' : '0'}}>Scopri le meraviglie di Bangkok con un'esperienza straordinaria confezionata su misura per te.</h3>
                <div className="title-container">
                    <h1 class="title-main"><SplitText copy="Vivi Bangkok a 360º" role="heading" /></h1>
                    <h2 className='title-main-sub text-white transparent'>Tour, biglietti e itinerari personalizzati.
                    </h2>
                </div>
                <h2 className='title-main-sub text-white transparent' style={{visibility : 'hidden'}}>Prenota subito le attivita' per il tuo soggiorno nella citta' degli angeli e parti senza pensieri!
                </h2>
            </div>
            <h2 class="title-page"><SplitText copy="Tour in primo piano" role="heading" /></h2>
            <ServicesPreview baseUrl='tours'></ServicesPreview>
            <h2 class="title-page"><SplitText copy="Consulenze in primo piano" role="heading" /></h2>
            <ServicesPreview baseUrl='services'></ServicesPreview>
            </div>
        </>
    );
}

export default Home;