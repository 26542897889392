import "animate.css/animate.min.css";
import "../css/common.css"
import "../css/mainpage.css"
import "../css/preview.css"

import { useNavigate } from "react-router-dom";



function Preview(props)
{
    let navigate = useNavigate();

    let id = "paragraphBackground" + props.index + props.baseUrl;

    function callbackFunc(entries, observer)
    {
        entries.forEach(entry => {    
            if(entry.isIntersecting)      
            {
                document.getElementById(entry.target.id).style.visibility = 'visible';
                document.getElementById(entry.target.id).classList.add('paragraph-container-animation');              
            }    
        });
    }

    function gotoArticle()
    {
        navigate('/' + props.baseUrl);
    }

    let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
    };

    let observer = new IntersectionObserver(callbackFunc, options);

    setTimeout(() => observer.observe(document.getElementById(id)),100);

    return(
        <div id={id} className='preview-container' style={{display : 'inline-block', visibility : 'hidden'}} onClick={gotoArticle}>
                <div  class="preview-image" style={{ backgroundImage: `url(${props.image})` }}>
                <div className="preview-content-box transparent">
                    <div class="preview-content text-blur">
                            <p class="preview-title">{props.title}</p>
                    </div>
                </div>
            </div>      
        </div>
    )  

}

export default Preview;