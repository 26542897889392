import "../css/common.css"
import "../css/contact.css"
import React, {useState} from 'react';
import closeButtonIcon from '../assets/icons/x-square.svg'



function Privacy(props)
{
    function closeForm()
    {
        props.onClose();
    }

    let Title = "Informativa sulla privacy";
    let paragraphs = [ 
        [ 
            "Titolare del trattamento",
            "Il titolare del trattamento e' Federica Furlani in qualita' di amministratore unico."
            + " L'indirizzo e-mail del titolare e': info@ilmioviaggioabangkok.net"
        ],
        [
            "Finalita' del trattamento",
            "I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, "
                            + "così come per le seguenti finalità: Contattare l’Utente, Interazione con social network "
                            + "e piattaforme esterne, Gestione dei pagamenti, Monitoraggio dell’infrastruttura, Gestione "
                            + "indirizzi e invio di messaggi email, Statistica, Interazione con le piattaforme di supporto, "
                            + "di feedback e di live chat."
        ],
        [
            "Tipologie di dati",
            "Vengono raccolti e trattati i seguenti Dati Personali: "
            + "Nome, Cognome, E-mail, Numero di telefono."
        ],
        [
            "Modalita' del trattamento",
            "Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. "
            + "Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. "
        ],
        [
            "Base giuridica del trattamento",
            "Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:"
            + "<ul>"
            + "<li>l’Utente ha prestato il consenso per una o più finalità specifiche</li>"
            + "<li>il trattamento è necessario all’esecuzione di un contratto con l’Utente e/o all’esecuzione di misure precontrattuali</li>"
            + "<li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare</li>"
            + "<li>il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o per l’esercizio di pubblici poteri di cui è investito il Titolare</li>"
            + "<li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi</li>"
            + "</ul>"
            + "E’ comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto."
        ],
        [
            "Luogo di conservazione",
            " Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare. "
            + "I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali. "
            + "L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati. "
        ],
        [
            "Periodo di conservazione",
            "I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti."
        ],
        [
            "Modalita' di esercizio dei diritti",
            "In qualsiasi momento sarà possibile richiedere gratuitamente la verifica, la cancellazione, la modifica dei propri dati, o ricevere l’elenco degli incaricati al trattamento, scrivendo una mail a info@ilmioviaggioabangkok.net. Le richieste saranno evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese."
        ],
        [
            "Modifiche a questa privacy policy",
            "Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su i siti e le applicazioni del Gruppo nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso il Titolare. "
            + "Si prega dunque di consultare regolarmente questa pagina."
        ]
    ]

    let paragraphRender = "";

    try{
        
        paragraphRender = paragraphs.map((paragraph) =>
            <div class="row transparent contact-row">
                <div class="col-sm-4 transparent"><p class="privacy-paragraph-title"><b>{paragraph[0]}</b></p></div>
                <div class="col-sm-8 transparent"><p class="privacy-paragraph-content" dangerouslySetInnerHTML={{ __html: paragraph[1]}}></p></div>  
            </div>
        )
    }
    catch{
        
    }

    return (
        <div class="background-unavailable" id="privacy" style={{"overflow" : "auto", zIndex : '101'}}>
            <div class="background-contact-box">
                <div class="contact-top-bar transparent">
                    <button class="close-button button-cst" onClick={closeForm}><i class="bi bi-x-square"></i></button>
                </div>
                <div class="contact-form-border transparent">
                    <div class="row transparent contact-row">
                        <h1>{Title}</h1>
                    </div>
                    {paragraphRender}
                </div>
            </div>
        </div>
    )
}


export default Privacy;