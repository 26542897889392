import './css/App.css';
import './fonts/AutourOne-Regular.ttf'
import './fonts/Pacifico-Regular.ttf'
import './fonts/DancingScript-Regular.ttf'
import ReactDOM from "react-dom/client";
import Layout from './components/Layout';
import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import Home from './pages/Home';
import {Tours, TourRoute} from './pages/Tours';
import {Services, ServiceRoute} from './pages/Services';
import Checkout from './pages/Checkout';
import AboutUs from './components/AboutUs';
import {ContextProvider} from './components/ShoppingCart'

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route index element={<Home/>}></Route>
              <Route path="services/:serviceID?" element={<Services/>}></Route>
              <Route path="tours/:tourID?" element={<Tours/>}></Route>
              <Route path="checkout" element={<Checkout/>}></Route>
              <Route path="aboutus" element={<AboutUs/>}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </div>
  );
}

export default App;
