import '../css/common.css'
import '../css/article.css'
import '../css/par.css'
import React, {useState, useContext} from 'react';
import DateTimePicker from 'react-datetime-picker';
import addMonths from '@jsbits/add-months';
import addDays from 'date-fns/addDays'
import NumericInput from 'react-numeric-input';
import {Context, AddToCart, GetCartCount } from './ShoppingCart';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'
import ReactImagesCarousel from 'react-images-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import TermsAndConditions from "../Views/TermsAndConditions";
import {JSONLD} from 'react-structured-data'
import { Product, Generic } from 'react-structured-data/dist/schemas';

function Article(props)
{
    const [viewBookItem, setviewBookItem] = useState(false);
    const [startDate, setStartDate] = useState(addDays(new Date(),3),"dd-MM-yyyy");
    const [selectedDate, setSelectedDate] = useState(addDays(new Date(),3));
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedMeetingPoint, setSelectedMeetingPoint] = useState('');
    const [alertText, setAlertText] = useState('');
    const [quantityAdult, setQuantityAdult] = useState(props.minimumBookingQuantity);
    const [quantityChildren, setQuantityChildren] = useState(0);
    const [quantityInfant, setQuantityInfant] = useState(0);
    const {items, setItems} = useContext(Context) || {};
    const [termsPage, setTermsPage] = useState ('');

    const handleChange = event =>  {
        switch(event.target.id){
            case "inputMeetingPoint":
                setSelectedMeetingPoint(event.target.value);
                break;
        }
      };

    const handleBookingQty = (index, value) =>
    {
        if(index == 0)
            setQuantityAdult(value);
        if(index == 1)
            setQuantityChildren(value);
        if(index == 2)
            setQuantityInfant(value);
    }

    const getMinimumBookingQuantity = (index) =>
    {
        return index == 0 ? props.minimumBookingQuantity : 0;
    }

    function openTermsPage()
    {
        setTermsPage(<TermsAndConditions onClose={closeTermsPage}></TermsAndConditions>);
    }

    function closeTermsPage()
    {
        setTermsPage('');
    }


    let bulletListRender = "";
    try{
        bulletListRender = props.keyPoints.map((keyPoint) =>
        <li><p>{keyPoint}</p></li>
        );
    }
    catch
    {
        bulletListRender = "";
    }

    let programListRender = ""
    try{
        programListRender = props.program.map((program) =>
            <li value={program}><div className='article-time-table-item'><p>{program.split(' - ')[0]}</p><p>{program.split(' - ')[1]}</p></div></li>
        )
    }
    catch{
        programListRender = ""
    }

    let meetingPointListRender = "";
    try{     
        if (props.meetingPoints[0] == 'No transfer'  || props.meetingPoints[0] == "Single")
        {
            meetingPointListRender = props.meetingPoints.slice(2).map((meetingPoint) =>
            <li><p>{meetingPoint}</p></li>
            );
        }
        else
        {
            meetingPointListRender = props.meetingPoints.map((meetingPoint) =>
            <li><p>{meetingPoint}</p></li>
            );
        }      

    }
    catch
    {
        meetingPointListRender = "";
    }

    let availableTimesSelectRender = ""
    try{
        availableTimesSelectRender = props.availableTimes.map((availableTime) =>
            <option value={availableTime}>{availableTime}</option>
        )
        availableTimesSelectRender = (
            <select onChange={(event) => {setSelectedTime(event.target.value);}} className='form-control'>
                <option value="">Seleziona...</option>
                {availableTimesSelectRender}
            </select>
        )
    }
    catch{
        availableTimesSelectRender = ""
    }

    let availableTimesSelectDisplay = "";

    if(props.availableTimes.length < 2)
    {
        availableTimesSelectDisplay = "none";
    }

    let availableTimesSelectSection = (
    <div class='row contact-row' style={{"display" : availableTimesSelectDisplay}}>
        <div class='col-sm-4'>
            <b>Ora:</b>
        </div>
        <div class='col-sm-8'>
            {availableTimesSelectRender}
        </div>
    </div>
    );

    let meetingPointsSelectRender = (
        <input type="text" id="inputMeetingPoint"
        className='form-control'
        onChange={handleChange}
        value={selectedMeetingPoint}></input>
    )
    //try{
    //    meetingPointsSelectRender = props.meetingPoints.map((meetingPoint) =>
    //        <option value={meetingPoint}>{meetingPoint}</option>
    //    )
    //    meetingPointsSelectRender = (
    //        <select onChange={(event) => {setSelectedMeetingPoint(event.target.value);}} className='form-control'>          
    //            <option value="">Seleziona...</option>
    //            {meetingPointsSelectRender}
    //            <option value='Ho bisogno di aiuto'>Ho bisogno di aiuto</option>
    //        </select>
    //    );
    //}
    //catch{
    //    meetingPointsSelectRender = ""
    //}

    let readState = (isOpen) =>
    {
        setviewBookItem(isOpen);
    }

    function AddItem()
    {
        let time = selectedTime
        if((time == '' || time == null) && props.availableTimes.length < 2)
        {
            time = props.availableTimes[0];
        }
        let actualPrice = props.bookingEntities[0]?.price ?? 0;
        if(quantityAdult == 1 && quantityChildren == 0 && props.meetingPoints[0] != "No transfer")
        {
            actualPrice = (parseInt(props.bookingEntities[0].price) + 3);
        }
        let childrenPrice = props.bookingEntities[1]?.price ?? 0;

        AddToCart({
            name : props.title,
            isService : props.isService,
            date : format(selectedDate, 'dd-MM-yyyy'),
            time : time,
            meetingPoint : selectedMeetingPoint,
            quantity : 1,
            quantityAdult : quantityAdult,
            quantityChildren : quantityChildren,
            quantityInfant : quantityInfant,
            price : props.price,
            priceAdult: actualPrice,
            priceChildren: childrenPrice
           });
        setAlertText(
            <div className='article-alert-box'>
                L'articolo e' stato aggiunto al <a href='/checkout'>carrello</a>.
            </div>
        );
        setItems(GetCartCount());

    }

    let imagesRender = "";
    try{
        imagesRender = props.image.map((image) =>
            <img className="article-main-image" src={image} alt={props.name}></img>
        );
    }
    catch{
        imagesRender = "";
    }


    let serviceBookingDetailsRender = !props.isService ? 
    "" :
    (
        <>
            <div class='row contact-row'>
                <p className='article-paragraph-title'>{props.title}</p>
            </div>
            <div class='row contact-row'>
                <div class='col-sm-4'>
                    <b>Servizio: </b>
                </div>
                <div class='col-sm-8'>
                    {props.subtitle}
                </div>
            </div>
            <div class='row contact-row'>
                <div class='col-sm-4'>
                    <b>Prezzo: </b>
                </div>
                <div class='col-sm-8'>
                    {props.price} &euro;
                </div>
            </div>
        </>
    ) 

    let bookingEntityRender = props.bookingEntities.map((bookingEntity, index) =>
    <div class='row contact-row'>
        <div class='col-sm-4'>
            <b>{bookingEntity.label} <br/>({bookingEntity.price} &euro;)</b>
        </div>
        <div class='col-sm-8'>
            <NumericInput
            mobile={true}
            className='form-control'
            selected={quantityAdult}
            onChange={num => handleBookingQty(index, num)}
            min={getMinimumBookingQuantity(index)}
            max='10'
            ></NumericInput>
        </div>
    </div>
    );

    let additionalNotes = props.bookingAdditionalMessages.map((note) =>
        <p className='font-verysmall'>
            {note}
        </p>
    );
        
    let pickPointRender = props.meetingPoints[0] == "No transfer" || props.meetingPoints[0] == "Single" ?
    "" :
    (
        <div class='row contact-row'>
        <div class='col-sm-4'>
            <b>Punto di raccolta:</b>
        </div>
        <div class='col-sm-8'>
            {meetingPointsSelectRender}
        </div>
        </div>
    )

    let lastDate = props.lastAvailableBooking == '' ?
    addMonths(new Date(), 3) :
    new Date(props.lastAvailableBooking.split('-')[0],parseInt(props.lastAvailableBooking.split('-')[1]) - 1,props.lastAvailableBooking.split('-')[2] );

    const disabledDates = props.disabledDates.map((item) =>
    new Date(item.split("-")[0],parseInt(item.split("-")[1]) - 1,item.split("-")[2] ))

    function disableDates (date, view)
    {
        return ((view === 'month') && // Block day tiles only
        disabledDates.some(disabledDate =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate()
        ) ||
        props.disabledDays.includes(date.getDay()))
    }
    let bookingDetailsRender = !props.showBookingDetails ? 
    (<></>) :
    (
        <>
                            <div class='row contact-row'>
                                <div class='col-sm-4'>
                                    <b>Data:</b>
                                </div>
                                <div class='col-sm-8'>
                                    <DateTimePicker
                                        className='form-control'
                                        clearIcon={null}
                                        value={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        minDate={startDate}
                                        maxDate={lastDate}
                                        format={"dd-MM-yyyy"}
                                        showDisabledMonthNavigation
                                        tileDisabled={({date, view}) => disableDates(date,view)}
                                                                />
                                </div>
                             </div>
                             {availableTimesSelectSection}
                             {bookingEntityRender}
                             {pickPointRender}
                             {additionalNotes}
        </>
    )

    let buttonRender = (
        <>
            <button className='btn btn-light button-row' style={{width : '100%'}} onClick={AddItem}>Acquista!</button>  
            <div className='button-row' style={{textAlign : 'center', display : 'none'}}>Oppure</div>
            <button className='btn btn-light button-row' style={{width : '100%' , display : 'none'}} onClick={AddItem}>Contattaci!</button>  
        </>
    )

    let programRender = programListRender == "" ?
    "" 
    :
    (
        <>
            <h2 className='article-paragraph-title'>Programma</h2>
            <p className='article-text'>
                <ul>
                    {programListRender}
                </ul>
                <hr/>
            </p>   
        </>
    )

    let meetingPointsRender = "";

    if(props.isService)
    {
        meetingPointsRender = "";
    }
    else if(props.meetingPoints[0] == "Single")
    {
        meetingPointsRender = (
            <>
                <h2 className='article-paragraph-title'>Punto di raccolta</h2>
                <p className='article-text'>
                    Il minibus partira' all'ora indicata da: {props.meetingPoints[1]}
                    <ul className='paddingT-s'>
                        {meetingPointListRender}
                    </ul>
                    <hr/>
                </p>
            </>
        )
    }
    else if(props.meetingPoints[0] == "No transfer")
    {
        meetingPointsRender = (
            <>
                <h2 className='article-paragraph-title'>Location</h2>
                <p className='article-text'>
                    Dovrai raggiungere il: {props.meetingPoints[1]}
                    <ul className='paddingT-s'>
                        {meetingPointListRender}
                    </ul>
                    <hr/>
                </p>   
            </>
        )
    }
    else
    {
        meetingPointsRender = (
            <>
                <h2 className='article-paragraph-title'>Punti di raccolta</h2>
                <p className='article-text'>
                    Se il tuo hotel o residence si trova in una delle seguenti zone sara' possibile venire a prenderti direttamente presso il tuo alloggio,
                    altrimenti ti sara' indicato un punto di incontro facilmente raggiungibile con i mezzi pubblici. 
                    <ul className='paddingT-s'>
                        {meetingPointListRender}
                    </ul>
                    <hr/>
                </p>
            </>
        )
    }
    

    let durationRender = props.isService ? "" :
    (
        <>
            <h2 className='article-paragraph-title'>Durata prevista</h2>
            <p className='article-text'>{props.duration}<hr/></p>
        </>
    );

    let priceRender = "" 

    if (props.isService == true)
    {
        priceRender = "";
    }
    else if(props.price != "Free children")
    {
        priceRender = props.bookingEntities.map((bookingEntity) =>
            <>
                <p className='article-text'>{bookingEntity.label}: {bookingEntity.price} &euro;</p>
            </>
        );
        priceRender = (
            <>
                <h2 className='article-paragraph-title'>Prezzi</h2>
                {priceRender}
                <p className='article-text'><hr/></p>
            </>
        );
    }
    else
    {
        priceRender = (
            <>
                <h2 className='article-paragraph-title'>Prezzi</h2>
                <p className='article-text'>Adulti e bambini: {props.bookingEntities[0].price} &euro;</p>
                <p className='article-text'>L'ingresso e' gratuito per i bambini sotto i 4 anni di eta', in passeggino o senza posto a sedere</p>
                <p className='article-text'><hr/></p>
            </>
        );
    }

    let cancellationPolicy = "";
    
    if (props.isService == true)
    {
        cancellationPolicy = "";
    }
    else
    {
        cancellationPolicy = (
            <>
                <h2 className='article-paragraph-title'>Politica di cancellazione</h2>
                <p className='article-text' dangerouslySetInnerHTML={{__html : props.cancellationPolicy}}>
                </p>
            </>
        )
    }

    return(
        <>
            {termsPage}
            <article className='article-box'>
                <p style={{fontSize : '0.7rem'}}> {props.isService ? "Consulenza" : "Tour" }</p>
                <h1>{props.title}</h1>
                <div class="article-main">
                    <div className="article-main-image-box">
                        <Carousel 
                            autoPlay={true} 
                            interval={3000}
                            infiniteLoop={true}
                            showThumbs={false}
                            preventMovementUntilSwipeScrollTolerance={true} 
                            swipeScrollTolerance={50}>
                            {imagesRender}
                        </Carousel>
                        <hr/>
                    </div>
                    <div className="article-price-box" >
                        <div >
                            {serviceBookingDetailsRender}
                            {bookingDetailsRender}
                            {buttonRender}
                            {alertText}
                        </div>
                    </div>
                </div>
                <h2 className='article-paragraph-title'>Descrizione: </h2>
                <p className='article-text' dangerouslySetInnerHTML={{__html : props.content}}></p>
                <p className='article-text'><hr/></p>
                {programRender}
                <h2 className='article-paragraph-title'>Cosa include questo servizio?</h2>
                <p className='article-text'>
                    <ul>
                        {bulletListRender}
                    </ul>
                    <hr/>
                </p>
                {meetingPointsRender}
                {durationRender}
                {priceRender}
                {cancellationPolicy}
            </article>
            <JSONLD>
                <Product 
                    name={props.title}
                    image={props.image[0]}>
                    <Generic type="offers" 
                        schema={
                            {'price': props.bookingEntities[0]?.price ?? props.price,
                             'priceCurrency' : 'EUR'
                            }}>             
                    </Generic>
                </Product>
            </JSONLD>
        </>
    )
}


export default Article;