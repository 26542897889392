import "../css/common.css"
import "../css/mainpage.css"
import AboutUsImage from '../assets/images/rooftop2.jpg'
import SplitText from "../Common";
import {useState} from 'react';

import instagramLogo from '../assets/images/instagramLogo.png'
import facebookLogo from '../assets/images/facebookLogo.png'
import tiktokLogo from '../assets/images/tiktokLogo.png'

import Privacy from "../Views/Privacy";
import TermsAndConditions from "../Views/TermsAndConditions";

var facebookUrl = "https://www.facebook.com/profile.php?id=100087326322374&mibextid=LQQJ4d";
var instagramUrl = "https://instagram.com/ilmioviaggioabangkok_official?igshid=YmMyMTA2M2Y=";
var tiktokUrl = "https://www.tiktok.com/@ilmioviaggioabangkok?_t=8agom7vvVdm&_r=1";


function Footer()
{
    const [privacyPage, setPrivacyPage] = useState ('');
    const [termsPage, setTermsPage] = useState ('');

    function openPrivacyPage()
    {
        setPrivacyPage(<Privacy onClose={closePrivacyPage}></Privacy>);
    }

    function closePrivacyPage()
    {
        setPrivacyPage('');
    }

    function openTermsPage()
    {
        setTermsPage(<TermsAndConditions onClose={closeTermsPage}></TermsAndConditions>);
    }

    function closeTermsPage()
    {
        setTermsPage('');
    }

    return (
        <>
        {privacyPage}
        {termsPage}
            <hr class="marginT-l"></hr>
            <div class="row paddingB-l" style={{width : '100%'}}>
                <div class="col-sm-1"></div>
                <div class="col-sm-3 footer-bottom">
                    <p class="title-footer">Contattaci</p>
                    <p class="font-small">Per qualsiasi informazione inviaci una e-mail a <b>info@ilmioviaggioabangkok.net</b></p>
                </div>
                <div class="col-sm-4 footer-bottom">
                    <p class="title-footer">Seguici</p>
                    <a href={instagramUrl} target="_blank"><img class="logo-social" src={instagramLogo}></img></a>
                    <a href={facebookUrl} target="_blank"><img class="logo-social" src={facebookLogo}></img></a>
                    <a href={tiktokUrl} target="_blank"><img class="logo-social" src={tiktokLogo}></img></a>           
                </div>
                <div class="col-sm-3 footer-bottom">
                    <p class="title-footer">Policy</p>
                    <a class="font-small" href="#" onClick={openPrivacyPage}>Privacy</a><br/>
                    <a class="font-small" href="#" onClick={openTermsPage}>Termini del servizio</a>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </>
        );
}

export default Footer;