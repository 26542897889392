import "animate.css/animate.min.css";
import "../css/common.css"
import "../css/mainpage.css"
import "../css/par.css"

import { useNavigate } from "react-router-dom";



function ArticlePreview(props)
{
    let navigate = useNavigate();

    let id = "paragraphBackground" + props.baseUrl + props.index;

    function callbackFunc(entries, observer)
    {
        entries.forEach(entry => {    
            if(entry.isIntersecting)      
            {
                document.getElementById(entry.target.id).style.visibility = 'visible';
                document.getElementById(entry.target.id).classList.add('paragraph-container-animation');              
            }    
        });
    }

    function gotoArticle()
    {
        //navigate('/' + props.baseUrl + '/' + props.index);
        //window.location.reload(true);
    }

    let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
    };

    let observer = new IntersectionObserver(callbackFunc, options);

    setTimeout(() => observer.observe(document.getElementById(id)),100);

    let containerCss=props.containerCss;
    let imageCss=props.imageCss;

    let infoRender = props.isService ? 
    (
        <>
            <p class="paragraph-preview-title"><b>{props.title}</b></p>
            <p className="paragraph-preview-price">{props.subtitle}</p>
            <div className="paragraph-preview-price-container">
                <p class="paragraph-preview-price"><b>Prezzo:</b> {props.price} &euro;</p>
            </div>
        </>

    )
    :
    (
        <>
            <p class="paragraph-preview-title"><b>{props.title}</b></p>
            <div className="paragraph-preview-price-container">
                <p class="paragraph-preview-price"><b>Durata:</b> {props.duration}</p>
                <p class="paragraph-preview-price"><b>Prezzo:</b> {props.price} &euro;</p>
            </div>
        </>

    )

    return(
        <a href={'/' + props.baseUrl + '/' + props.index}>
            <div id={id} className={containerCss} style={{ visibility : 'hidden'}} onClick={gotoArticle}>
                <div  class={imageCss} style={{ backgroundImage: `url(${props.image[0]})` }}>
                </div>    
                <div class="transparent" style={{height: "120px"}}>
                        <div class="paragraph-preview-content text-align-left">
                            {infoRender}
                        </div>
                </div>   
            </div>
        </a>
    )  

}

export default ArticlePreview;