import "../css/common.css"
import "../css/shoppingcarticon.css"
import {Context} from './ShoppingCart'
import {useContext } from 'react';


function ShoppingCartIcon(props) 
{
    let {items, setItems} = useContext(Context);
    

    if (items > 0)
    {
        let fixedClass = props.fixed ? 'shopping-icon-container-fixed' : '';
        return(
            <div className={fixedClass}>
                <a href='/checkout' className='shopping-icon-container nav-item-font-color'>
                    <div className='shopping-icon-badge'>{items}</div>
                    <i className="bi bi-cart2 shopping-icon"></i>
                </a>
            </div>
        )
    }
    else
    {
        return(
            <div className='shopping-icon-container' style={{display : props.fixed ? 'none' : 'initial'}}>
                <i className="bi bi-cart2 shopping-icon"></i>
            </div>
        )
    }

}

export default ShoppingCartIcon;