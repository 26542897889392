import "../css/common.css"
import "../css/mainpage.css"
import SplitText from "../Common";
import ArticlePreview from "../components/ArticlePreview"
import Article from "../components/Article"
import config from "../Config.json";


import { Route, useParams} from 'react-router-dom';
import { useState, useEffect } from "react"


export function ToursRoute()
{

    let toursPageRender = "";
    var [contentLoaded, setContentLoaded] = useState(false);
    const [toursList, setToursList] = useState({});

    if(!contentLoaded)
    {
        fetch(config.backendUrl + "/content/tours", {method: "GET"})
        .then(response => {
            response.json().then((body)=> 
            {
              setToursList(body);
              setContentLoaded(true);
            });
        }); 
    }
    try{
        if(contentLoaded)
        {
            toursPageRender = toursList.map((tour) =>
            <Route path={tour.index} component={<Article/>}></Route>
            )
        }
    }
    catch{

    }

    return (
        {toursPageRender}
    )
}

export function Tours()
{
    var [contentLoaded, setContentLoaded] = useState(false);
    const [toursList, setToursList] = useState({});
    window.scrollTo(0, 0)

    if(!contentLoaded)
    {
        fetch(config.backendUrl + "/content/tours", {method: "GET"})
        .then(response => {
            response.json().then((body)=> 
            {
              setToursList(body);
              setContentLoaded(true);
            });
        }); 
    }

    let titleRender = "";
    let toursPreviewTitleRender = "";
    let toursPreviewRender = "";
    let toursRender = "";
    let horizontalLineRender = "";

    let params = useParams();

    if (params.tourID)
    {
        if(contentLoaded)
        {
            toursRender = (
                <Article
                    ID={params.tourID}
                    isService={toursList[params.tourID -1].service}
                    title={toursList[params.tourID -1].title}
                    keyPoints={toursList[params.tourID -1].keyPoints}
                    program={toursList[params.tourID -1].program}
                    meetingPoints={toursList[params.tourID -1].meetingPoints}
                    availableTimes={toursList[params.tourID -1].availableTimes}
                    price={toursList[params.tourID -1].price}
                    bookingEntities={toursList[params.tourID -1].bookingEntities}
                    minimumBookingQuantity={toursList[params.tourID -1].minimumBookingQuantity}
                    image={toursList[params.tourID -1].imageUrl}
                    content={toursList[params.tourID - 1].description}
                    duration={toursList[params.tourID - 1].duration}
                    bookingAdditionalMessages={toursList[params.tourID - 1].bookingAdditionalMessages}
                    showBookingDetails={true}
                    disabledDates={toursList[params.tourID - 1].disabledDates}
                    disabledDays={toursList[params.tourID - 1].disabledDays}
                    lastAvailableBooking={toursList[params.tourID - 1].lastAvailableBooking}
                    cancellationPolicy={toursList[params.tourID - 1].cancellationPolicy}
                ></Article>
            )
            toursPreviewTitleRender = (
                <h2 className="article-paragraph-title text-align-left">Altri tour:</h2>
            )
            horizontalLineRender = <hr style={{'margin-top' : '5rem'}}/>;
        }
    }
    else{
        titleRender = (
            <h1 class="title-page"><SplitText copy="Tour" role="heading" /></h1>
        )
    }

    try{
        if(contentLoaded)
        {
            toursPreviewRender = toursList.map((tours) =>
            <ArticlePreview
                containerCss="paragraph-preview-container"
                imageCss="paragraph-preview-image"
                baseUrl='tours'
                index={tours.index}
                isService={tours.service}
                title={tours.title}
                keyPoints={tours.keyPoints}
                price={tours.bookingEntities[0].price}
                image={tours.imageUrl}
                showImageOnPreview={true}
                duration={tours.duration}
                delay={0}> 
            </ArticlePreview>
            )
        }
        else
        toursPreviewRender = <p>Caricando i contenuti...</p>

    }
    catch{
        toursPreviewRender = <p>Errore durante il caricamento</p>
    }

    return (
        <div class="page-background">
            {titleRender}
            {toursRender}
            {horizontalLineRender}
            {toursPreviewTitleRender}
            <div className='main-grid'>
                {toursPreviewRender}
            </div>
        </div>
    );
}
