import { Outlet, Link } from "react-router-dom";
import Navbar from "./NavBar";
import Footer from "./Footer";


import { useState } from "react";



const Layout = () => {
  
  return (
    <>
        <Navbar></Navbar>
        <Outlet />
        <Footer></Footer>
    </>
  )
};

export default Layout;