import { ReactSession } from 'react-client-session';
import React, { createContext, useContext, useState } from 'react';
ReactSession.setStoreType("localStorage");
  
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
    const [items, setItems] = useState(GetCartCount());
  
    return (
        <Context.Provider value={{ items, setItems }}>
            {children}
        </Context.Provider>
    );
};


export function AddCheckoutDetails(contactInfo)
{
    ReactSession.set('buyerInfo',contactInfo);
}

export function GetCheckoutDetails()
{
    return ReactSession.get('buyerInfo');
}


export function AddToCart(item){
    let currentCart = ReactSession.get('shoppingCart') || 
    {
        totalItems : 0,
        totalPrice : 0,
        items : []
    };
    currentCart.items.push(item);
    currentCart.totalItems += 1;
    if(item.isService)
        currentCart.totalPrice += (item.price * item.quantity) || 0;
    else
    {
        currentCart.totalPrice += (item.priceAdult * item.quantityAdult) || 0;
        currentCart.totalPrice += (item.priceChildren * item.quantityChildren) || 0;
    }    

    ReactSession.set('shoppingCart', currentCart);
}

export function GetCart(){
    return ReactSession.get('shoppingCart');
}

export function GetCartCount(){
    try{
        return ReactSession.get('shoppingCart').totalItems;
    }
    catch{
        return 0;
    }
}

export function CartIsEmpty(){
    return ReactSession.get('shoppingCart') == null;
}

export function EmptyCart(){
    ReactSession.remove('shoppingCart');
    ReactSession.remove('buyerInfo');
}

export function VerifyLastCheckout(){
    return ReactSession.get('lastOrderStatus');
}

export function CheckoutComplete(status){
    ReactSession.set('lastOrderStatus', status);
}

export function ClearLastCheckout(){
    ReactSession.remove('lastOrderStatus');
}
