import "../css/common.css"
import "../css/mainpage.css"
import AboutUsImage from '../assets/images/whoAmI.jpg'
import SplitText from "../Common";

let AboutUsText = "Mi chiamo Federica e vengo da un paesino del Friuli Venezia Giulia. A novembre 2022, insieme a mio marito, ho lasciato l’italia e sono partita per Bangkok con l’obiettivo di inseguire il mio sogno. \
È stata una scelta difficile ma fortemente voluta, dopo molti dubbi e indecisioni ho lasciato il posto fisso e mi sono lanciata in questa nuova avventura. \
I viaggi sono la mia più grande passione, e l’Asia in particolare: ho sempre amato l’oriente, non so se per la sua diversità o per quell’atmosfera quasi mistica che pervade questi paesi. \
Nel 2010 feci uno stage di 6 mesi in un’azienda di Shanghai: mi innamorai a prima vista. \
Ci sono voluti 12 lunghi anni per effettuare un cambio radicale come questo, coltivavo questo sogno nel cassetto come se non potesse mai avverarsi, e invece eccomi qui. \
Ho aperto ILMIOVIAGGIOABANGKOK con l’obiettivo di trasmettere ai viaggiatori le stesse emozioni che provo io giorno dopo giorno nello scoprire le meraviglie di questa città. \
Bangkok è una città spesso sottovalutata, con un fascino incredibile e difficilmente ritrovabile da altre parti. Coniuga il lusso e la modernità dei rooftop e dei centri commerciali al romanticismo dei tempi antichi e dei quartieri fatti bancarelle e di gente vera. \
È una perla d’oriente, e dopo aver goduto del tramonto sul Chao Phraya non si può fare altro che innamorarsi."


function AboutUs()
{
    return (
        <div className="page-background">
            <h2 class="title-page" id="AboutUs"><SplitText copy="Chi sono?" role="heading" /></h2>
            <div class="row">
                <div class="col-sm-8 vertical-center justify-text">
                    <p class="paragraph-padding">{AboutUsText}</p>                   
                </div>
                <div class="col-sm-4">
                    <img style={{"max-height" : "70vh",  "max-width" : "100%"}} src={AboutUsImage}></img>
                </div>
            </div>
        </div>
        );
}

export default AboutUs;