import "../css/common.css"
import "../css/navbar.css"
import instagramLogo from '../assets/images/instagramLogo.png'
import facebookLogo from '../assets/images/facebookLogo.png'
import tiktokLogo from '../assets/images/tiktokLogo.png'
import {CartIsEmpty, GetCartCount} from './ShoppingCart'
import ShoppingCartIcon from './ShoppingCartIcon'; 
import {useState} from 'react';
import ContactForm from "../Views/ContactForm";

const delay = 5;


var hide = true;
var facebookUrl = "https://www.facebook.com/profile.php?id=100087326322374&mibextid=LQQJ4d";
var instagramUrl = "https://instagram.com/ilmioviaggioabangkok_official?igshid=YmMyMTA2M2Y=";
var tiktokUrl = "https://www.tiktok.com/@ilmioviaggioabangkok?_t=8agom7vvVdm&_r=1";

function toggleMenu ()
{
    hide = !hide;
    document.getElementById("navbarContent").style.visibility = hide ? "hidden" : "visible";
    document.getElementById("navbarContent").style.height = hide ? 0 : "auto";
}

function showContactForm()
{
    document.getElementById("contactScreen").style.visibility = "visible";
}


function Navbar(){
    
    const [contactForm, setContactForm] = useState ('');

    function openContactForm()
    {
        setContactForm(<ContactForm onClose={closeContactForm}></ContactForm>);
    }

    function closeContactForm()
    {
        setContactForm('');
    }

    setTimeout(() => {
        let display = /*GetCartCount() == 0 ? 'none' :*/ 'initial';
        var icons = document.getElementsByClassName('shopping-icon-badge');
        for(let i = 0; i < icons.length; i++) {
            icons[i].style.display = display;
        }
    },500);

    return (
        <>
        {contactForm}
            <nav class="navbar navbar-expand-lg navbar-light nav-sticky nav-border" style={{backgroundColor : 'white', zIndex : 10, width : '100%'}}>
                <a href="/"><p class="title-header">Il mio viaggio a Bangkok</p></a>
                <button href="#" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                <div class="collapse navbar-collapse" id="navbarContent">      
                        <ul class="navbar-nav mr-auto" style={{margin : 'auto'}}>
                            <li class="nav-item nav-item-cst">
                                <a class="nav-link nav-item-font-color" href="/">Home</a>
                            </li>
                            <li class="nav-item nav-item-cst">
                                <a class="nav-link nav-item-font-color" href="/services">Consulenze</a>
                            </li>
                            <li class="nav-item nav-item-cst">
                                <a class="nav-link nav-item-font-color" href="/tours">Tour</a>
                            </li>
                            <li class="nav-item nav-item-cst">
                                <a class="nav-link nav-item-font-color" href="/aboutus">Chi sono</a>
                            </li>
                            <li class="nav-item nav-item-cst">
                                <a class="nav-link nav-item-font-color" href="#" onClick={openContactForm}>Contattaci</a>
                            </li>    
                            <li class="nav-item nav-item-cst">
                                <a class="nav-link nav-item-font-color" style={{paddingTop : '0'}} href="/checkout"><ShoppingCartIcon/></a>
                            </li>                      
                        </ul>
                        <p class="title-links" >
                                <a href={instagramUrl} target="_blank"><img class="logo-social" src={instagramLogo}></img></a>
                                <a href={facebookUrl} target="_blank"><img class="logo-social" src={facebookLogo}></img></a>
                                <a href={tiktokUrl} target="_blank"><img class="logo-social" src={tiktokLogo}></img></a>      
                            </p>  
                </div>
                    <ShoppingCartIcon fixed={true}/>
            </nav>
       </>
    )       
}


export default Navbar;