import "../css/common.css"
import "../css/contact.css"
import React, {useState} from 'react';
import closeButtonIcon from '../assets/icons/x-square.svg'
import Privacy from "../Views/Privacy";

function showPrivacy()
{
    document.getElementById("privacy").style.visibility = "visible";
}

function ContactForm(props)
{
    function closeForm()
    {
        props.onClose();
    }

    const [inputName, setInputName] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const [privacyPage, setPrivacyPage] = useState ('');
    const [operationInProgress, setOperationInProgress] = useState(false);

    function openPrivacyPage()
    {
        setPrivacyPage(<Privacy onClose={closePrivacyPage}></Privacy>);
    }

    function closePrivacyPage()
    {
        setPrivacyPage('');
    }

    const handleChange = event =>  {
        switch(event.target.id){
            case "inputName":
                setInputName(event.target.value);
                break;
            case "inputEmail":
                setInputEmail(event.target.value);
                break;
            case "inputPhone":
                setInputPhone(event.target.value);
                break;
            case "inputMessage":
                setInputMessage(event.target.value);
                break;
        }
      };

    function validateData()
    {
        var privacyAccepted = document.getElementById('acceptPrivacy')
        if(privacyAccepted.checked)
            return true;

        return false;
    }

    async function submitForm()
    {
        setOperationInProgress(true);
        document.getElementById("alertBox").innerText = "";
        document.getElementById("alertBox").classList.remove("alert-box-error");
        if(!validateData())
        {
            document.getElementById("alertBox").innerText = "E' necessario fornire il consenso in merito all'informativa per la privacy";
            document.getElementById("alertBox").classList.add("alert-box-error");
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: inputEmail,
                name: inputName,
                phone: inputPhone,
                message: inputMessage
            })
        };

        try{
            const response = await fetch('https://backend-ilmioviaggioabangkok.onrender.com/contact', requestOptions);
            //const response = await fetch('https://localhost:44327/contact', requestOptions);
            if(response.status == 200)
                document.getElementById("alertBox").innerText = 'Grazie per averci contattato. Riceverai una e-mail di conferma e sarai ricontattato al piu\' presto';
            else
            {
                document.getElementById("alertBox").innerText = 'Qualcosa e\' andato storto, ti preghiamo di riprovare tra qualche istante.'
                document.getElementById('alertBox').classList.add('alert-box-error');
            }
        }
        catch{
            document.getElementById("alertBox").innerText = 'Qualcosa e\' andato storto, ti preghiamo di riprovare tra qualche istante.'
            document.getElementById('alertBox').classList.add('alert-box-error');
        }
        finally{
            setInputName('');
            setInputEmail('');
            setInputPhone('');
            setInputMessage('');
            var privacyAccepted = document.getElementById('acceptPrivacy');
            privacyAccepted.checked = false;
            setOperationInProgress(false);
        }

            //const data = await response.json();
        //this.setState({ postId: data.id });

    }

    let operationInProgressRender = !operationInProgress ? '' :
    (
        <div style={
            {
                position : 'absolute',
                width : '100vw',
                height : '100vh',
                backgroundColor : 'rgba(50,50,50,0.5)',
                left : 0,
                top : 0,
                zIndex : 1000,
                fontSize : '1.5rem',
                paddingTop : '30vh'
            }}>
            <p style={{
                marginLeft: '10vw',
                marginRight : '10vw',
                padding: '0.5rem',
                backgroundColor : 'white'
            }}>
                Stiamo processando la tua richiesta, per favore attendi...
            </p> 
        </div>
    )

    return (
        <>
        {privacyPage}
            <div class="background-unavailable" id="contactScreen" style={{ zIndex : '100'}}>
                <div class="background-contact-box">
                    <div class="contact-top-bar transparent">
                        <button class="close-button button-cst" onClick={closeForm}><i class="bi bi-x-square"></i></button>
                    </div>
                    <div class="contact-form-border transparent">
                        <div class="row transparent contact-row">
                            <h3>Inviaci la tua richiesta!</h3>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Nome:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputName"
                                    onChange={handleChange}
                                    value={inputName}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                E-mail:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputEmail"
                                    name="message"
                                    onChange={handleChange}
                                    value={inputEmail}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Telefono:
                            </div>
                            <div class="col-sm-7 transparent">
                                <input type="text" id="inputPhone"
                                    onChange={handleChange}
                                    value={inputPhone}
                                    style={{"width" : "90%"}}></input>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-4 transparent">
                                Messaggio:
                            </div>
                            <div class="col-sm-7 transparent">
                                <textarea id="inputMessage"
                                    onChange={handleChange}
                                    value={inputMessage}
                                    style={{"width" : "90%" , "resize" : "none"}}
                                    rows="7"></textarea>
                            </div>
                            <div class="col-sm-1 transparent"></div>
                        </div>
                        <div class="row transparent contact-row">
                            <div class="col-sm-2 transparent"></div>
                            <div class="col-sm-1 transparent">
                                <input type="checkbox" id="acceptPrivacy" class="checkbox-cst"></input>
                            </div>
                            <div class="col-sm-9 transparent">
                                <label for="acceptPrivacy">Ho preso visione dell'informativa sulla <b onClick={openPrivacyPage}>privacy</b></label>
                            </div>
                        </div>
                        <button class="btn btn-light button-cst" onClick={submitForm}>Invia</button>
                        <div class="alert-box" id="alertBox"></div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ContactForm;